<template>
    <div class="experts-wrapper" v-if="this.expertdata.attributes">
        <!--<router-link class="tile" to="/expert">-->
        <div class="tile" @click="goToCT">
            <div class="fields">
                <div v-for="(image, index) in this.expertFields" 
                    :key="'img'+index" class="field" 
                    :style="fieldStyle(image.attributes.drupal_internal__tid)">
                </div>
            </div>
            <div class="info">
                <div class="profile">
                    <!--<img :src="profilephoto" alt="">-->
                    <images :imagedata="this.expertdata" :fieldname="'field_expert_photo'" :breakstyles="{'0': 'xs_image', '576': 'sm_image'}"></images>
                </div>
                <div class="incontri">
                    {{ this.expertdata.attributes.field_disponibilita_in_ore }}
                </div>
            </div>
            <div class="content">
                <div class="first">
                    <h4 class="name">{{ this.expertdata.attributes.field_name + " " + this.expertdata.attributes.field_surname }}</h4>
                    <p>{{ this.expertdata.attributes.field_expert_professione }}</p>
                </div>
                
                <div class="pre-tags">
                    <h5>INTERESSI E COMPETENZE</h5>
                    <div class="tags">
                    <div class="tag" v-for="(tag, index) in this.expertdata.attributes.field_expert_interessi" :key="'tag'+index">
                        {{ tag }}
                    </div>
                </div>
                </div>
                
            </div>
            
        </div>
        <div class="button-container">
            <button class="to-detail btn" @click="goToCT">
                SCOPRI DI PIÙ
            </button>
        </div>
    </div>
</template>

<script>
import { fieldColorExpert, fieldSrcExpert } from '../libs/utils.js'
//import { fetchRoutes } from '../libs/drupalClient.js'
import Images from './Images.vue'
export default {
    name: 'expert-tile',
    data: () => {
        return {
            ctTitle: "not-found"
        }
    },
    props: {
        expertdata: Object,
        filtersdata: Array,
        routes: Array
    },
    components: {
        Images
    },
    computed: {
        profilephoto() {
            return this.expertdata.field_expert_photo ? (process.env.VUE_APP_ENDPOINT + this.expertdata.field_expert_photo.attributes.uri.url) : ""
        },
        expertFields() {
            var array = []
            if(this.expertdata.field_expert_ambito)
                this.expertdata.field_expert_ambito.forEach(f => {
                    var find = this.filtersdata.find(ff => f.attributes.name.toLowerCase() == ff.attributes.name.toLowerCase())
                    if(find)
                        array.push(find)
                })
            return array
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    methods: {
        fieldStyle(image) {
            var f = this.filtersdata.find(i => i.attributes.drupal_internal__tid==image)
            var n = f ? f.attributes.name : 'not-found'
            
            return '--background-color: ' + fieldColorExpert(image) + ';' +
                '--background-image: url(' + fieldSrcExpert(image) + ');' +
                '--field-text: "' + n + '";' + 
                '--field-width: ' + n.length*9 + 'px;'
        },
        goToCT() {
            this.$router.push(
                //(this.lang==this.defaultLang ? '' : ('/' + this.lang )) + 
                '/expert/' + this.expertdata.attributes.drupal_internal__nid +  '/' + this.ctTitle
            )
        }
    }, 
    mounted() {
        this.routes.find(r => {
            if(parseInt(r.nid)==this.expertdata.attributes.drupal_internal__nid && r.lang==this.lang) {
                this.ctTitle = r.title
            }   
        })
    },
    watch: {
        expertdata() {
            this.routes.find(r => {
                if(parseInt(r.nid)==this.expertdata.attributes.drupal_internal__nid && r.lang==this.lang) {
                    this.ctTitle = r.title
                }   
            })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>