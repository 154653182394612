<template>
    <div v-if="this.projectdata"
        :class="['projects-wrapper', this.donation ? 'donation' : '' , addclass]"        
        @click="click">
        <div class="tile" @click="goToCT">
            <div class="image" :style="settorehover">
                <images :imagedata="this.projectdata" :fieldname="'field_project_foto'" :breakstyles="{'0': 'xs_image', '768': 'sm_image'}"></images>
            </div>
            <div class="content">
                <div class="field" 
                    :style="fieldStyle(this.projectdata.field_project_settore.attributes.drupal_internal__tid)" 
                    :title="this.projectdata.field_project_settore.attributes.name"></div>
                <h6 class="title">{{ this.projectdata.attributes.title }}</h6>
            </div>
        </div>
        <div class="button-container">
            <slot>
                <a class="to-detail btn" @click="goToCT"><span>SCOPRI DI PIÙ</span></a>
            </slot>
        </div>
    </div>
</template>


<script>
import { fieldColorProject, fieldSrcProject } from '../libs/utils.js'
//import { fetchRoutes } from '../libs/drupalClient.js'
import Images from './Images.vue'
export default {
    name: 'project-tile',
    data: () => {
        return {
            ctTitle: "not-found"
        }
    },
    props: {
        projectdata: Object,
        filtersdata: Array,
        donation: Boolean,
        addclass: String,
        routes: Array
    },
    components: {
        Images
    },
    computed: {
        image(){
            return process.env.VUE_APP_ENDPOINT+ this.projectdata.field_project_foto.attributes.uri.url
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        settorehover() {
            return '--settorehover: "' + this.projectdata.field_project_settore.attributes.name + '";'
        }
    },
    methods: {
        click() {
            this.$emit('select')
        },
        goToCT() {
            if(this.donation) {
                /*let link = this.$router.resolve({ path: 
                    (this.lang==this.defaultLang ? '' : ('/' + this.lang )) + 
                    '/project/' + this.projectdata.attributes.drupal_internal__nid +  '/' + this.ctTitle
                });
                window.open(link.href, '_blank');*/
                this.$emit('open-detail')
            } else {
                this.$router.push(
                    (this.lang==this.defaultLang ? '' : ('/' + this.lang )) + 
                    '/project/' + this.projectdata.attributes.drupal_internal__nid +  '/' + this.ctTitle
                )
            }
        },
        fieldStyle(image) {
            var f = this.filtersdata.find(i => i.attributes.drupal_internal__tid==image)
            var n = f ? f.attributes.name : 'not-found'
            
            return '--background-color: ' + fieldColorProject(image) + ';' +
                '--background-image: url(' + fieldSrcProject(image) + ');' +
                '--field-text: "' + n + '";' + 
                '--field-width: ' + n.length*7 + 'px;'
        }
    }, 
    mounted() {
        this.routes.find(r => {
            if(parseInt(r.nid)==this.projectdata.attributes.drupal_internal__nid && r.lang==this.lang) {
                this.ctTitle = r.title
            }   
        })
    },
    watch: {
        projectdata() {
            this.routes.find(r => {
                if(parseInt(r.nid)==this.projectdata.attributes.drupal_internal__nid && r.lang==this.lang) {
                    this.ctTitle = r.title
                }   
            })
        }
    }
}
</script>

<style lang="scss" scoped>
</style>