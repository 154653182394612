<template>
    <div id="totop" class="no-header-template pattern-bg form-sent">
        <div class="container-espe">

           <div style="margin: 5em auto; text-align: center;">
               <h1>ERROR 404 - NOT FOUND</h1>
           </div>
           
            <div class="button-container">
                <a href="/" class="btn">TORNA ALLA HOMEPAGE</a>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'not-working',
    components: {
    },
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "",
                    body: {
                        summary: ""
                    }
                } 
            },
            currentPageId: ""
        }
    },
    methods: {  
    },
    computed: {
        formResults() {
            return this.$store.state.sentForm
        },
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    mounted() {
        /*setTimeout(() => {
            this.$router.push('/')
        }, 7000);*/
    }
}
</script>

<style lang="scss">
</style>