import store from '../store'

//formattazione dei nomi dei specifici content type
export function slugify(string) {
  var s = string
    .toLowerCase().normalize("NFD")
    .replace(/ /g, "-")
    .replace(/[-]+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/[\u0300-\u036f]/g, "");
    //console.log(s)
    return s
}

export function personName(person) {
  return person.attributes.field_first_name + " " + person.attributes.field_last_name;
}

export function convertMonth(num) {
  if(store.state.currentLang=='it')
    switch(num) {
      case 1: case '01': return 'Gennaio';
      case 2: case '02': return 'Febbraio';
      case 3: case '03': return 'Marzo';
      case 4: case '04': return 'Aprile';
      case 5: case '05': return 'Maggio';
      case 6: case '06': return 'Giugno';
      case 7: case '07': return 'Luglio';
      case 8: case '08': return 'Agosto';
      case 9: case '09': return 'Settembre';
      case 10: return 'Ottobre';
      case 11: return 'Novembre';
      case 12: return 'Dicembre';
      default: return num;
    }
  else
    switch(num) {
      case 1: case '01': return 'January';
      case 2: case '02': return 'February';
      case 3: case '03': return 'March';
      case 4: case '04': return 'April';
      case 5: case '05': return 'May';
      case 6: case '06': return 'June';
      case 7: case '07': return 'July';
      case 8: case '08': return 'August';
      case 9: case '09': return 'September';
      case 10: return 'October';
      case 11: return 'November';
      case 12: return 'December';
      default: return num;
    }
}

export function reWriteDate(date) {
  // formato di entrata: stringa (e.g. 2021-06-04)
  
  let y = date.substr(0, 4);
  let m = convertMonth(date.substr(5,2));
  let d = date.substr(8, 11);
  
  // fotmato di uscita -> giugno 4, 2021
  return m + " " + d + ", " + y;
}

export function shuffleArray(array) {
  var currentIndex = array.length,  randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export function fieldColorExpert(field) {
  var c = ''
  switch(field) {
      case 1: c='#F3B185'; break;
      case 2: c='#BFD4E4'; break;
      case 3: c='#CFC5D4'; break;
      case 4: c='#C3C6C5'; break;
      case 5: c='#E0D2B9'; break;
      case 6: c='#8CBDC1'; break;
      case 7: c='#DCD0C6'; break;
      default: c='#999999'; break;
  }
  return c
}

export function fieldColorProject(field) {
  var c = ''
  switch(field) {
      case 9: c='#D91B5C'; break;
      case 10: c='#009999'; break;
      case 8: c='#D9C89E'; break;
      default: c='#999999'; break;
  }
  return c
}

export function srcImage(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./' + image)
}

export function fieldSrcDarkExpert(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-dark-' + image + '.svg')
}
export function fieldSrcDarkProject(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-project-dark-' + image + '.svg')
}
export function fieldSrcExpert(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-' + image + '.svg')
}
export function fieldSrcProject(image) {
  var p = require.context('../assets/', false, /\.svg$/)
  return p('./field-project-' + image + '.svg')
}