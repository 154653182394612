<template>
    <div>
        <basic-page 
            class="homepage" addedclass="homepage" 
            :pagedata="this.pageData" 
            :bannerimg="this.backgroundImage" 
            :fieldname="'field_multipla'">
            <div class="container-espe">
                <h2 class="central">Come funziona ExpeRotary</h2>

                <div :class="['video-container', 'video_hq', { videoPlaying: isPlaying }]" 
                    ref="videoContainer" @click="playVideo('video_hq')">
                    <div class="controls" ref="videoControls">
                        <img class="play" src="../assets/play-button.svg" width="71" height="71" alt="">
                        <img class="pause" src="../assets/pause-button.svg" width="71" height="71" alt="">
                    </div>

                    <video ref="video_hq" @ended="onEnd()" poster="../assets/poster.jpg">
                        <source src="../assets/video/video.mp4" type="video/mp4">
                    </video>
                </div>

                <div :class="['video-container', 'video_low', { videoPlaying: isPlaying }]" 
                    ref="videoContainer" @click="playVideo('video_low')">
                    <div class="controls" ref="videoControls">
                        <img class="play" src="../assets/play-button.svg" width="71" height="71" alt="">
                        <img class="pause" src="../assets/pause-button.svg" width="71" height="71" alt="">
                    </div>

                    <video ref="video_low" @ended="onEnd()" poster="../assets/poster.jpg">
                        <source src="../assets/video/video-mobile.mp4"  type="video/mp4">
                    </video>
                </div>

                <div class="phases">
                    <div class="phase">
                        <img class="phase-logo" src="../assets/icon-come-funziona-trova.svg" alt="">
                        <h1 class="num">1</h1>
                        <div class="content">
                            <h4 class="name">TROVA</h4>
                            <p class="text">Con chi ti piacerebbe poter avere un confronto?</p>
                        </div>
                    </div>
                    <div class="phase">
                        <img class="phase-logo" src="../assets/icon-come-funziona-presentati.svg" alt="">
                        <h1 class="num">2</h1>
                        <div class="content">
                            <h4 class="name">PRESENTATI</h4>
                            <p class="text">Così l’esperto che hai selezionato saprà chi incontrerà</p>
                        </div>
                    </div>
                    <div class="phase">
                        <img class="phase-logo" src="../assets/icon-come-funziona-conferma.svg" alt="">
                        <h1 class="num">3</h1>
                        <div class="content">
                            <h4 class="name">CONFERMA</h4>
                            <p class="text">Hai la possibilità di fare libera donazione alla causa che preferisci per confermare la richiesta: se scegli di donare, noi doneremo altrettanto per raddoppiare l’impatto</p>
                        </div>
                    </div>
                    <div class="phase">
                        <img class="phase-logo" src="../assets/icon-come-funziona-confronto.svg" alt="">
                        <h1 class="num">4</h1>
                        <div class="content">
                            <h4 class="name">CONFRONTO</h4>
                            <p class="text">60 minuti di confronto con la persona che può aiutarti a costruire il futuro</p>
                        </div>
                    </div>
                    <div class="phase">
                        <img class="phase-logo" src="../assets/icon-come-funziona-raccontaci.svg" alt="">
                        <h1 class="num">5</h1>
                        <div class="content">
                            <h4 class="name">RACCONTACI</h4>
                            <p class="text">Com’è andata così da poter migliorare anche grazie ai tuoi consigli</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-espe button-container">
                <router-link to="/gli-esperti" class="btn">
                    TROVA LA PERSONA GIUSTA
                </router-link>
            </div>

            <div class="container-espe backstep scheda white-bg" style="padding-bottom: 0;">
                <h2 class="scegli">Scegli chi incontrare</h2>
                <div class="experts">
                    <expert-tile v-for="(exp, index) in expertData" :key="'expert'+index" 
                        :expertdata="exp" 
                        :filtersdata="expertsfiltersData"
                        :routes="routesData"
                        ></expert-tile>
                </div>

                <br>
                <br>

                <div class="button-container">
                    <router-link class="btn yellow" to="/gli-esperti">
                        SCOPRILI TUTTI
                    </router-link>
                </div>

                <br>
                <br>

                <h3 class="gray-quote-block no-frame-top"> Servire al di sopra di ogni interesse personale. </h3>
                <h3 class="gray-quote-author no-frame-bottom" style="margin-bottom: 0;"> ROTARY </h3>
                <!--<div class="fullwidth-image" style="background-image: url(imagefull-min.jpg); background-size: cover; background-position: center center;"></div>-->
            </div>

            <div class="container-espe" style="padding-top: 0; padding-bottom: 0;">
                <images :imgclass="'fullwidth-image'" :imagedata="backgroundImage" :fieldname="'field_multipla'" :resultindex="1" 
                    :breakstyles="{'0': 'sm_image', '576': 'md_image', '992': 'lg_image'}"></images>
            </div>

            <div class="double-tile">
                <div class="element1 white-bg">
                    <div class="container-espe">
                        <h3 style="margin: .5em 0;">Il progetto</h3>
                        <p>
                            Il progetto EXPEROTARY mette a disposizione l’esperienza dei rotariani e dei loro amici per offrire questo confronto ai giovani.
                        </p>
                        <div class="button-container">
                            <router-link to="/il-progetto" class="btn">
                                LEGGI DI PIÙ
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="element2 blue-bg">
                    <div class="container-espe blue-bg">
                        <h3 style="margin: .5em 0;">Diventa un esperto</h3>
                        <p>
                            Sei una persona con esperienza in un campo e ti piacerebbe condividerla con un giovane? Contatta il Rotary Club più vicino a te e chiedi di ExpeRotary.
                        </p>
                        <div class="button-container">
                            <router-link to="/diventa-un-esperto" class="btn">
                                CONTATTACI
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="container-espe">
                <h2>Experotary supporta</h2>
                <div class="projects" >
                    <project-tile 
                        :routes="routesData"
                        :projectdata="project" 
                        class="project-wrapper" 
                        v-for="(project, index) in this.projectData" 
                        :key="'project' + index" 
                        :filtersdata="projectsfiltersData"
                        :donation="false">
                    </project-tile>
                </div>
            </div>

        </basic-page>
    </div>
</template>

<script>
import BasicPage from './BasicPage.vue'
import ExpertTile from '../components/ExpertTile.vue'
import ProjectTile from '../components/ProjectTile.vue'
import { fetchNodes, fetchTaxonomy, fetchRoutes } from '../libs/drupalClient'
import { shuffleArray } from '../libs/utils'
import Images from '../components/Images.vue'

export default {
    name: 'home',
    components: { 
        BasicPage, 
        ExpertTile, 
        ProjectTile,
        Images
    },
    data: () => {
        return {
            pageData: {
                attributes: {
                    title: "60 MINUTI PER IL TUO FUTURO",
                    field_home_intro: {
                        processed: 'Se sei un giovane studente e stai pensando al tuo futuro, <span class="orange">EXPEROTARY</span> ti regala un confronto di <span>60 minuti</span> con chi può aiutarti a <span>costruirlo</span>.'
                    }
                }
            },
            expertsfiltersData: [],
            projectsfiltersData: [],
            expertData: [],
            projectData: [],
            routesData: [],
            isPlaying: false,
            carouselSettings: {
                dots: true,
                focusOnSelect: true,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 3,
                touchThreshold: 5
            },
            backgroundImage: {}
        }
    },
    methods: {
        play(video) {
            this.$refs[video].play()
        },
        pause(video) {
            this.$refs[video].pause()
        },
        onEnd() {
            this.isPlaying = false
        },
        playVideo(video) {
            if (!this.isPlaying) {
                this.play(video)
                this.isPlaying = true
            } else {
                this.isPlaying = false
                this.pause(video)
            }
        },
        onresize() {
            console.log('prova')
            if(this.isPlaying) {
                this.$refs['video_hq'].pause()
                this.$refs['video_low'].pause()
                this.isPlaying = false
            }
        }
    },
    computed: {
        /*backgroundImage() {
            //return 'background-image: url("") background-size: cover; background-position: center center;'
        },*/
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        }
    },
    async mounted() {
        window.addEventListener("resize", this.onresize)

        await fetchRoutes().then(r => this.routesData = r.routes)

        await fetchNodes('expert', { 
            include: ['field_expert_ambito', 'field_expert_photo']
        }, this.lang)
        .then(res => {
            this.expertData = shuffleArray(res.filter(r => r.attributes.field_disponibilita_in_ore>0).slice(0, 3))
        })

        await fetchNodes('project', { include: ['field_project_settore', 'field_project_foto']}, this.lang)
        .then(res => {
            this.projectData = shuffleArray(res.slice(0, 3))
        })

        fetchTaxonomy('ambito_di_esperienza', {}, this.lang).then(res => {
            this.expertsfiltersData = res
        })

        fetchTaxonomy('settore', {}, this.lang).then(res => {
            this.projectsfiltersData = res
        })

        fetchNodes('ui_image', { 
            include: ['field_multipla'],
            filters: [{
                key: 'drupal_internal__nid',
                value: '141'
            }]}, this.lang)
        .then(res => {
            this.backgroundImage = res[0]
            //console.log('banner Homepage', this.backgroundImage)
        })
    }
}
</script>

<style lang="scss">
.yellow {
    background-color: #FEAF40;
}
</style>