<template>
        <div class="no-header-template pattern-bg">

            <div class="container-espe" v-if="this.ctData && this.ctData.attributes">
                <h2>Ciao {{ this.ctData.attributes.field_name + " " + this.ctData.attributes.field_surname }},</h2>
                <p>Rispondere alle domande qui sotto ti impegnerà meno di 5 minuti e ci aiuterà a rendere il servizio di Experotary sempre più semplice e utile.</p>
                <p>Grazie per il tuo tempo!</p>
            </div>

            <form v-if="tipo=='studente'" @submit.prevent="processForm">
                <div class="container-espe backstep endborder gray-bg">
                    <div class="modulo-esperto">
                    
                        <h3>Come sei venuto a conoscenza di Experotary?</h3>
                        <div class="quest-radios">
                            <div class="radio-choice">
                                <input required type="radio" id="campo11" name="st_02" value="1" v-model="stFormData.st_02">
                                <label for="campo11">Passa parola</label>    
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo12" name="st_02" value="2" v-model="stFormData.st_02">
                                <label for="campo12">Ricerca sul Web</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo13" name="st_02" value="3" v-model="stFormData.st_02">
                                <label for="campo13">Social Media</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo14" name="st_02" value="4" v-model="stFormData.st_02">
                                <label for="campo14">Altro</label>
                            </div>
                        </div>

                        <h3>Hai già fatto l'incontro?</h3>
                        <div class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo21" name="st_01" value="1" v-model="stFormData.st_01">
                                <label for="campo21">Sì</label>    
                            </div>

                            <div class="radio-choice">
                                <input type="radio" id="campo22" name="st_01" value="0" v-model="stFormData.st_01">
                                <label for="campo22">No</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Quanto è durato l'incontro?</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input required type="radio" id="campo31" name="st_03" value="1" v-model="stFormData.st_03">
                                <label for="campo31">Meno di 30 minuti</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo32" name="st_03" value="2" v-model="stFormData.st_03">
                                <label for="campo32">45 minuti</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo33" name="st_03" value="3" v-model="stFormData.st_03">
                                <label for="campo33">60 minuti</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo34" name="st_03" value="4" v-model="stFormData.st_03">
                                <label for="campo34">Più di 60 minuti</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">In che modalità è avvenuto l'incontro?</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input required type="radio" id="campo41" name="st_04" value="1" v-model="stFormData.st_04">
                                <label for="campo41">In presenza</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo42" name="st_04" value="2" v-model="stFormData.st_04">
                                <label for="campo42">Al telefono</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo43" name="st_04" value="3" v-model="stFormData.st_04">
                                <label for="campo43">Piattaforma di web conferencing</label>
                            </div>
                        </div>
                        <div v-if="!this.hideFields && stFormData.st_04==='3'" class="quest-radios webconferencing">
                            <div class="radio-choice">
                                <input type="radio" id="campo411" name="st_041" value="1" v-model="stFormData.st_041">
                                <label for="campo411" class="skype"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo412" name="st_041" value="2" v-model="stFormData.st_041">
                                <label for="campo412" class="whatsapp"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo413" name="st_041" value="3" v-model="stFormData.st_041">
                                <label for="campo413" class="zoom"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo414" name="st_041" value="4" v-model="stFormData.st_041">
                                <label for="campo414" class="teams"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo415" name="st_041" value="5" v-model="stFormData.st_041">
                                <label for="campo415" class="hangouts"></label>
                            </div>
                        </div>


                        <h3 v-if="!this.hideFields">L'esperto che ho scelto era esattamente la persona che cercavo</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input required type="radio" id="campo51" name="st_05" value="1" v-model="stFormData.st_05">
                                <label for="campo51">Molto d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo52" name="st_05" value="2" v-model="stFormData.st_05">
                                <label for="campo52">D'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo53" name="st_05" value="3" v-model="stFormData.st_05">
                                <label for="campo53">Poco d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo54" name="st_05" value="4" v-model="stFormData.st_05">
                                <label for="campo54">Incerto</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo55" name="st_05" value="5" v-model="stFormData.st_05">
                                <label for="campo55">Per niente d'accordo</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">L'esperto ha risposto in maniera soddisfacente alle mie domande</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input required type="radio" id="campo61" name="st_06" value="1" v-model="stFormData.st_06">
                                <label for="campo61">Molto d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo62" name="st_06" value="2" v-model="stFormData.st_06">
                                <label for="campo62">D'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo63" name="st_06" value="3" v-model="stFormData.st_06">
                                <label for="campo63">Poco d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo64" name="st_06" value="4" v-model="stFormData.st_06">
                                <label for="campo64">Incerto</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo65" name="st_06" value="5" v-model="stFormData.st_06">
                                <label for="campo65">Per niente d'accordo</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Il confronto mi aiuterà a prendere una decisione importante</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input required type="radio" id="campo71" name="st_07" value="1" v-model="stFormData.st_07">
                                <label for="campo71">Molto d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo72" name="st_07" value="2" v-model="stFormData.st_07">
                                <label for="campo72">D'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo73" name="st_07" value="3" v-model="stFormData.st_07">
                                <label for="campo73">Poco d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo74" name="st_07" value="4" v-model="stFormData.st_07">
                                <label for="campo74">Incerto</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo75" name="st_07" value="5" v-model="stFormData.st_07">
                                <label for="campo75">Per niente d'accordo</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Come valuti il progetto Experotary?</h3>
                        <div v-if="!this.hideFields" class="quest-radios stars">
                            <input :class="{'full': stFormData.st_08 > 1}" type="radio" id="campo81" name="st_08" value="1" v-model="stFormData.st_08">
                            <input :class="{'full': stFormData.st_08 > 2}" type="radio" id="campo82" name="st_08" value="2" v-model="stFormData.st_08">
                            <input :class="{'full': stFormData.st_08 > 3}" type="radio" id="campo83" name="st_08" value="3" v-model="stFormData.st_08">
                            <input :class="{'full': stFormData.st_08 > 4}" type="radio" id="campo84" name="st_08" value="4" v-model="stFormData.st_08">
                            <input required type="radio" id="campo85" name="st_08" value="5" v-model="stFormData.st_08">
                        </div>

                        <h3 v-if="!this.hideFields">Consiglieresti Experotary ai tuoi amici?</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input required type="radio" id="campo91" name="st_09" value="1" v-model="stFormData.st_09">
                                <label for="campo91">Sì</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo92" name="st_09" value="0" v-model="stFormData.st_09">
                                <label for="campo92">No</label>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="gray-bg">
                    <div class="container-espe backstep pattern-bg">
                        <div class="modulo-esperto">
                            <h3>Come credi che il progetto possa migliorare?</h3>
                            <textarea :class="[liveCountDown(stFormData.st_10) > 100 ? 'errore' : '']" 
                                name="st_10" rows="15" v-model="stFormData.st_10"
                                placeholder="Ti diamo 150 caratteri che potrebbero essere riportati sul sito di Experotary come testimonianza di un esperto">
                            </textarea>
                        
                            <br>
                            <br>

                            <div class="button-container">
                                <button class="btn">Invia</button>
                            </div>

                            <br>
                            <br>

                        </div>
                    </div>
                </div>
            </form>

            <form v-if="tipo=='esperto'" @submit.prevent="processForm">
                <div class="container-espe backstep endborder gray-bg">
                    <div class="modulo-esperto">
                    
                        <h3>Hai già fatto l'incontro?</h3>
                        <div class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo11" name="ex_01" value="1" v-model="exFormData.ex_01">
                                <label for="campo11">Sì</label>    
                            </div>

                            <div class="radio-choice">
                                <input type="radio" id="campo12" name="ex_01" value="0" v-model="exFormData.ex_01">
                                <label for="campo12">No</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Quanto è durato l'incontro?</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo21" name="ex_02" value="1" v-model="exFormData.ex_02">
                                <label for="campo21">Meno di 30 minuti</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo22" name="ex_02" value="2" v-model="exFormData.ex_02">
                                <label for="campo22">45 minuti</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo23" name="ex_02" value="3" v-model="exFormData.ex_02">
                                <label for="campo23">60 minuti</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo24" name="ex_02" value="4" v-model="exFormData.ex_02">
                                <label for="campo24">Più di 60 minuti</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">In che modalità è avvenuto l'incontro?</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo31" name="ex_03" value="1" v-model="exFormData.ex_03">
                                <label for="campo31">In presenza</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo32" name="ex_03" value="2" v-model="exFormData.ex_03">
                                <label for="campo32">Al telefono</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo33" name="ex_03" value="3" v-model="exFormData.ex_03">
                                <label for="campo33">Piattaforma di web conferencing</label>
                            </div>
                        </div>
                        <div v-if="!this.hideFields && exFormData.ex_03==='3'" class="quest-radios webconferencing">
                            <div class="radio-choice">
                                <input type="radio" id="campo311" name="ex_031" value="1" v-model="exFormData.ex_031">
                                <label for="campo411" class="skype"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo312" name="ex_031" value="2" v-model="exFormData.ex_031">
                                <label for="campo412" class="whatsapp"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo313" name="ex_031" value="3" v-model="exFormData.ex_031">
                                <label for="campo413" class="zoom"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo314" name="ex_031" value="4" v-model="exFormData.ex_031">
                                <label for="campo414" class="teams"></label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo315" name="ex_031" value="5" v-model="exFormData.ex_031">
                                <label for="campo415" class="hangouts"></label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Credo di essere stato l'esperto giusto per lo studente</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo41" name="ex_04" value="1" v-model="exFormData.ex_04">
                                <label for="campo41">Molto d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo42" name="ex_04" value="2" v-model="exFormData.ex_04">
                                <label for="campo42">D'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo43" name="ex_04" value="3" v-model="exFormData.ex_04">
                                <label for="campo43">Poco d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo44" name="ex_04" value="4" v-model="exFormData.ex_04">
                                <label for="campo44">Incerto</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo45" name="ex_04" value="5" v-model="exFormData.ex_04">
                                <label for="campo45">Per niente d'accordo</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Le domande che lo studente mi ha posto erano mirate e inerenti al mio campo di competenza</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo51" name="ex_05" value="1" v-model="exFormData.ex_05">
                                <label for="campo51">Molto d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo52" name="ex_05" value="2" v-model="exFormData.ex_05">
                                <label for="campo52">D'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo53" name="ex_05" value="3" v-model="exFormData.ex_05">
                                <label for="campo53">Poco d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo54" name="ex_05" value="4" v-model="exFormData.ex_05">
                                <label for="campo54">Incerto</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo55" name="ex_05" value="5" v-model="exFormData.ex_05">
                                <label for="campo55">Per niente d'accordo</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Credo di aver dato dei consigli che gli saranno utili per le scelte future</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo61" name="ex_06" value="1" v-model="exFormData.ex_06">
                                <label for="campo61">Molto d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo62" name="ex_06" value="2" v-model="exFormData.ex_06">
                                <label for="campo62">D'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo63" name="ex_06" value="3" v-model="exFormData.ex_06">
                                <label for="campo63">Poco d'accordo</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo64" name="ex_06" value="4" v-model="exFormData.ex_06">
                                <label for="campo64">Incerto</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo65" name="ex_06" value="5" v-model="exFormData.ex_06">
                                <label for="campo65">Per niente d'accordo</label>
                            </div>
                        </div>

                        <h3 v-if="!this.hideFields">Come valuti il progetto Experotary?</h3>
                        <div v-if="!this.hideFields" class="quest-radios stars">
                            <input :class="{'full': exFormData.ex_07 > 1}" type="radio" id="campo71" name="ex_07" value="1" v-model="exFormData.ex_07">
                            <input :class="{'full': exFormData.ex_07 > 2}" type="radio" id="campo72" name="ex_07" value="2" v-model="exFormData.ex_07">
                            <input :class="{'full': exFormData.ex_07 > 3}" type="radio" id="campo73" name="ex_07" value="3" v-model="exFormData.ex_07">
                            <input :class="{'full': exFormData.ex_07 > 4}" type="radio" id="campo74" name="ex_07" value="4" v-model="exFormData.ex_07">
                            <input type="radio" id="campo95" name="ex_07" value="5" v-model="exFormData.ex_07">
                        </div>

                        <h3 v-if="!this.hideFields">Conosci qualche esperto che pensi possa dare un grande contributo al progetto?</h3>
                        <div v-if="!this.hideFields" class="quest-radios">
                            <div class="radio-choice">
                                <input type="radio" id="campo81" name="ex_08" value="1" v-model="exFormData.ex_08">
                                <label for="campo81">Sì</label>
                            </div>
                            <div class="radio-choice">
                                <input type="radio" id="campo82" name="ex_08" value="0" v-model="exFormData.ex_08">
                                <label for="campo82">No</label>
                            </div>
                        </div>

                        <p style="margin-bottom: 0;">
                            Se hai risposto sì, condividigli/le il link qui sotto in modo che possa contattarci:
                        </p>
                        <router-link to="/diventa-un-esperto" target="_blank" style="width: fit-content;"><h3 style="margin-top: 0;">
                            https://experotary.it/diventa-un-esperto
                        </h3></router-link>

                        <br>

                        <div class="button-container">
                            <button class="btn" type="button">Condividi il link</button>
                        </div>

                        <br>

                    </div>
                </div>

                <div class="gray-bg">
                    <div class="container-espe backstep pattern-bg">
                        <div class="modulo-esperto">
                            <h3>Come credi che il progetto possa migliorare?</h3>
                            <textarea :class="[liveCountDown(exFormData.ex_09) > 100 ? 'errore' : '']"
                                name="ex_09" rows="15" v-model="exFormData.ex_09"
                                placeholder="Ti diamo 150 caratteri che potrebbero essere riportati sul sito di Experotary come testimonianza di un esperto" >
                            </textarea>
                        
                            <br>
                            <br>

                            <div class="button-container">
                                <button class="btn">Invia</button>
                            </div>

                            <br>
                            <br>

                        </div>
                    </div>
                </div>
            </form>

        </div>
</template>

<script>

import { fetchNodes } from '../libs/drupalClient'
export default {
    name: 'feedback',
    data: () => {
        return {
            pageData: { 
                attributes: {
                    title: "",
                    body: {
                        summary: ""
                    }
                } 
            },
            ctData: {},
            currentPageId: "56537a3e-50ac-43df-9040-c1613fc1832d",
            isPlaying: false,
            foundContent: false,
            tipo: '',
            meetingData: {},
            //TO DO: ri-scrivere template dinamico in base ai campi
            stFormData: {
                type: 'student',
                uniqid: '',
                st_02: 0,
                st_01: -1,
                st_03: 0,
                st_04: 0,
                st_041: 0,
                st_05: 0,
                st_06: 0,
                st_07: 0,
                st_08: 0,
                st_09: -1,
                st_10: ""
            },
            exFormData: {
                type: 'expert',
                uniqid: '',
                ex_01: -1,
                ex_02: 0,
                ex_03: 0,
                ex_04: 0,
                ex_05: 0,
                ex_06: 0,
                ex_07: 0,
                ex_08: -1,
                ex_09: ""
            }
        }
    },
    metaInfo() {
        return {
            title: 'Feedback'
        }
    },
    computed: {
        lang() {
            return this.$store.state.currentLang
        },
        defaultLang() {
            return this.$store.state.defaultLang
        },
        personid() {
            return this.$route.params.personid
        },
        meetingid() {
            return this.$route.params.meetingid
        },
        hideFields() {
            if(this.tipo=='studente')
                return this.stFormData.st_01==0
            else
                return this.exFormData.ex_01==0
        }
    },
    methods: {
        liveCountDown(msg) {
            console.log(msg.match(/\s+/g)?.length || 0)
            return msg.match(/\s+/g)?.length || 0
        },
        processForm(event) {
            event.preventDefault()

            var url = new URL(process.env.VUE_APP_POSTURL + "/feedback");

            if(this.tipo=='studente')
                Object.entries(this.stFormData).forEach(([k, v]) => url.searchParams.append(k, v));
            else if(this.tipo=='esperto')
                Object.entries(this.exFormData).forEach(([k, v]) => url.searchParams.append(k, v));

            fetch(url, {
                method: "POST",
                body: {},
                redirect: "follow"
            })
            .then(res => { 
                //console.log(res)
                if(res.status==200) {
                    this.$store.commit('SET_FORM_DATA', {
                        type: "feedback",
                        name: this.ctData.attributes.field_name,
                        surname: this.ctData.attributes.field_surname,
                        email: this.ctData.attributes.field_email,
                        donation_amount: 0,
                        donation_project: "",
                        expert_contact: ""
                    })
                    this.$router.push('/confirmed')
                }
            })
            .catch(error => { console.log(error) })
        },
        play() {
            this.$refs.video.play();
        },
        pause() {
            this.$refs.video.pause();
        },
        onEnd() {
            this.isPlaying = false
        },
        playVideo() {
            if (!this.isPlaying) {
                this.play()
                this.isPlaying = true
            } else {
                this.isPlaying = false
                this.pause()
            }
        },

        async checkContent() {
            var found = ''

            await fetchNodes('confronto', {
                filters: [{
                    key: 'field_uniqid',
                    value: this.meetingid
                }],
                include: ['field_ref_expert', 'field_ref_student']
            }, this.lang).then(res => {
                //se il meeting non è stato confermato
                if(res.length==0)
                    found = 'not-found'
                //se hanno già compilato entrambi il form di feedback
                else if(
                    res[0].attributes.field_feedback_ricevuto_expert && res[0].field_ref_expert.attributes.drupal_internal__nid == this.personid
                    || res[0].attributes.field_feedback_ricevuto_student && res[0].field_ref_student.attributes.drupal_internal__nid == this.personid)
                    found = 'not-working'
                //se almeno uno dei due form è ancora disponibile
                else {
                    this.meetingData = res[0]
                }
            })

            if(found=='') {
                await fetchNodes('expert', {
                    filters: [{
                        key: 'drupal_internal__nid',
                        value: this.personid
                    }],
                    include: ['field_expert_photo', 'field_expert_ambito']

                }, this.lang).then(res => {
                    if(res.length>0)
                        if(res[0].attributes.drupal_internal__nid == this.meetingData.field_ref_expert.attributes.drupal_internal__nid) {
                            this.ctData = res[0]
                            this.foundContent = true
                            this.tipo = 'esperto'
                            this.exFormData.uniqid = this.meetingid
                        }  else {
                            found = 'not-found'
                        }
                })

                await fetchNodes('student', {
                    filters: [{
                        key: 'drupal_internal__nid',
                        value: this.personid
                    }]
                }, this.lang).then(res => {
                    if(res.length>0) {
                        if(res[0].attributes.drupal_internal__nid == this.meetingData.field_ref_student.attributes.drupal_internal__nid) {
                            this.ctData = res[0]
                            this.foundContent = true
                            this.tipo = 'studente'
                            this.stFormData.uniqid = this.meetingid
                        } else {
                            found = 'not-found'
                        }
                    } else if(!this.ctData.attributes)
                        found = 'not-found'
                })
            }

            if(found=='not-found')
                this.$router.push('/404/not-found')
            if(found=='not-working')
                this.$router.push('/not-working')
        }
    },
    mounted() {
       this.checkContent()
    }
}
</script>

<style lang="scss" scoped>
</style>