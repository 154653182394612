import { render, staticRenderFns } from "./ExpertTile.vue?vue&type=template&id=79bc2bf2&scoped=true&"
import script from "./ExpertTile.vue?vue&type=script&lang=js&"
export * from "./ExpertTile.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79bc2bf2",
  null
  
)

export default component.exports