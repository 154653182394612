import Vue from 'vue'
import VueRouter from 'vue-router'
import { fetchRoutes } from '../libs/drupalClient'
import store from '../store'
import Home from '../views/Home'
import Feedback from '../views/Feedback'
import NotWorking from '../views/NotWorking'
import NotFound from '../views/NotFound'
//import CookiePolicy from '../views/CookiePolicy'

let itemsToUse = [ VueRouter ]
itemsToUse.forEach((item)=>{
  Vue.use(item)
})

export function router() {
  return fetchRoutes().then(data => {

    //per estrarre e mostrare prima le routes con la lingua di default --> necessario per il giusto matching ordinato
    // ?? fattibile già dal back ??
    var fetchedRoutes = data.routes.filter(r => r.lang==data.langcode).sort((a,b) => a['dynamic-group'] > b['dynamic-group'] ? 1 : -1)
    data.routes.filter(r => r.lang!=data.langcode).forEach(f =>
      fetchedRoutes.push(f)
    )

    var ffroutes = []
    //imposta la lingua di default nello store dell'applicazione
    store.commit('SET_DEFAULT_LANG', data.langcode)
    
    //lista di tutte le possibili lingue --> per costruzione routes Homepage tradotta
    var languages = data.languages
    store.commit('SET_LANGUAGES', languages)
  //console.log('All JCONFIG generated routes', fetchedRoutes)

    //helpers
    var pagine = []
    var path, comp
    //var lang = '/:lang'

    //COSTRUZIONE DELLA STRUTTURA DI ROUTING + ALIAS PER TRADUZIONI
    //per ogni route generata
    fetchedRoutes.forEach(f => {
      //carica il componente di Vue corrispondente
      comp = () => import( '../views/' + f['component-name'] + '.vue')
      //a meno che non si tratti della Homepage, caso speciale gestito alla fine
      //if(f['component-name']!='Home') {

        //se non è già stata analizzata e aggiunta la route
        if(!pagine.includes(f['component-name'])) {
          //se si tratta di una route di pagine statiche
          if(f['dynamic-group']=="") {
            path = f.path

            //?? da eliminare ?? - se route sono ordinate è utile SOLO IN CASO DI PAGINE PRESENTI PER UNA SOLA LINGUA
            //path = (f.lang==data.langcode) ? f.path : (lang + f.path) 

          //altrimenti, se si tratta di un esempio di pagina di dettaglio
          } else {
            //aggiungi direttamente tutto il dynamic-group al routing
            path = f['dynamic-group']
          }

          //analizza e aggiungi la pagina alla struttura, alias calcolati in seguito
          ffroutes.push({ path, component: comp, name: f['component-name'], lang: f.lang, props: true, alias: [] })
          pagine.push(f['component-name'])

        //se il tipo di pagina è già stato analizzato, si tratta di un'alias
        } else {
          //si trova la route già aggiunta che determina il gruppo di routing
          var as = ffroutes.find(a => a.name === f['component-name'])

          //se si tratta di una pagina statica, si aggiunge il path specifico con la lingua per la traduzione
          // [/lingua/pagina]
          if(f['dynamic-group']=="") {
            as.alias.push(f.path)
          } else {
            //se è una pagina di dettaglio, si aggiunge un alias uguale al dynamic-group ma con la lingua davanti
            // [/lingua/dynamic-group/:id/:title]
            if(!as.alias.includes(f['dynamic-group']) && f['dynamic-group']!=as.path)
              as.alias.push(f['dynamic-group'])

            //--> PROBLEMA: se non ci sono traduzioni per un CT, deve apparire in tutte le lingue come originale, o non appare dove non è tradotto?
          }
        }
      //}
    })

    //infine si aggiunge dinamicamente la route della Home come caso speciale
    //calcolando gli alias per ogni lingua, che corrispondono semplicemente a [/:lang] per ognuna
    var Halias = []
    languages.forEach(l => {
      if(l!=data.langcode)
        Halias.push('/' + l)
    })

    ffroutes.push({
      path: '/not-working',
      lang: data.langcode,
      component: NotWorking,
      name: 'NotWorking',
      alias: []
    })

    ffroutes.push({
      path: '/404/not-found',
      lang: data.langcode,
      component: NotFound,
      name: 'NotFound',
      alias: []
    })

    ffroutes.push({
      path: '/feedback/:personid/:meetingid',
      lang: data.langcode,
      component: Feedback,
      name: 'Feedback',
      alias: []
    })

    ffroutes.find(r => r.name=='Home').redirect = '/'

    ffroutes.push({
      path: '/', 
      lang: data.langcode,
      component: Home, 
      name: 'Home',
      alias: Halias
    })

    //console.log('FINAL ROUTES', ffroutes)

    return new VueRouter({
      scrollBehavior() {
        return { x: 0, y: 0 };
      },
      mode: 'history',
      base: '',
      routes: ffroutes
    })
    
  })
}
