<template>
    <div :class="['header-intro', addedclass]" title="">
        <span class="shadow-bg"></span>
        <images :imagedata="backimg"
            :fieldname="fieldname" 
            :breakstyles="{'0': 'mb_banner', '768': 'md_image', '992': 'lg_image'}"
            :imgclass="'backimg'"
            :priority="'high'"
        ></images>


        <div class="container-espe container-header">
            <div v-if="addedclass=='homepage'" class="header">
                <div class="riga1">
                    <h1 class="obj1">60</h1>
                    <h1 class="obj2">minuti <br> per il tuo</h1>
                </div>
                <h1 class="riga2">FUTURO</h1>
            </div>
            
            <h1 v-else class="header central"> {{ this.title }} </h1>
            <h3 v-if="this.subtitle" v-html="this.subtitle" class="header central"></h3>
        </div>

    </div>
</template>

<script>
import Images from '../components/Images.vue'
export default {
    name: 'header-intro',
    data: () => {
        return {
            responsiveImg: ""
        }
    },
    components: {
        Images
    },
    props: {
        title: String,      //titolo h1 della pagina
        subtitle: String,   //sottotitolo h2 della pagina
        backimg: Object,    //dati completi dell'immagine (per ricavare alt e dimensioni iniziali)
        fieldname: {        //campo dove sono contenuti i dati effettivi dell'immagine (url per generare styles con API)
            default: 'field_banner_top',    //per l'header il campo default è quello con nome del banner, 
            type: String                    //da specficare se la pagina non ha un CT (caso Homepage)
        },
        addedclass: String  //classe custom per banner per pagine specifiche
    },
    mounted() {
        /*
        if(this.backimg.attributes)
            fetch(process.env.VUE_APP_ENDPOINT + '/images-url/' + 
                (this.addedclass=='homepage' ? '141/field_multipla' 
                : this.backimg.attributes.drupal_internal__nid + '/field_banner_top'))
            .then(res => res.json())
            .then(json => {
                this.responsiveImg = json
            })
        */
    },
    watch: {
        /*backimg() {
            fetch(process.env.VUE_APP_ENDPOINT + '/images-url/' + 
                (this.addedclass=='homepage' ? '141/field_multipla' 
                : this.backimg.attributes.drupal_internal__nid + '/field_banner_top'))
            .then(res => res.json())
            .then(json => {
                this.responsiveImg = json
            })
        }*/
    }
}
</script>

<style lang="scss">
    .container-header {
        margin:0 auto 1rem auto;
        padding-bottom: 0;
    }
    
</style>