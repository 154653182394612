<template>
<header v-on:scroll="scrollFunction()" :class="{sticky : windowScrolled}">
  <nav :class="{'navbar': true, 'colored': menuOpen}">
    <div class="headcontainer">

    <router-link to="/" class="navbar-brand" @click.native="closeMenu">
      <img src="@/assets/logo.svg" alt="logo" class="logo-desktop"/>
      <img v-if="menuOpen" src="@/assets/logo-white.svg" alt="logo" class="logo-mobile"/>
      <img v-else src="@/assets/logo.svg" alt="logo" class="logo-mobile"/>
    </router-link>

    <div :class="['inner-container', menuOpen ? 'show' : '']" >
      <!-- create a list of menu routes -->
      <ul class="navbar-nav">
        <!-- for every top level menu route ... -->
        <li v-for="(item, index) of menuItems" :key="'menu' + index"
          :class="['nav-item', index === submenuOpen ? 'active' : '']"
        >
          <!-- if has children is not clickable -->
          <div v-if="item.children && item.children.length > 0" class="nav-item-content" @click="openSubmenu(index)">
            <span class="nav-link dropdown">{{ item.label }}</span>
          </div>

          <!-- if it's a top level route menu, it is a link -->
          <div v-else class="nav-item-content">
            <router-link class="nav-link" :to="item.url" @click.native="closeMenu">
              {{ item.label }}
            </router-link>
          </div>

          <!-- if it has children, create a list of sub links -->
          <ul
            v-if="item.children && item.children.length > 0"
            :class="['submenu', submenuOpen === index ? 'active': '']"
            v-show="submenuVisible"
          >
            <li v-for="(child, index) of item.children" :key="index">
              <!-- create a clickable link for every child -->
              <div>
                <router-link :to="child.url" @click.native="closeMenu">
                  {{ child.label }}
                </router-link>
              </div>
            </li>
          </ul>
        </li>

        <!--<li class="nav-item">
          <div class="lang">
            <a 
              v-for="(ll, index) in languages" 
              :key="'lang'+index"
              :class="{'nav-link': true, 'selected': lang==ll}" 
              @click="changelang(ll)">{{ ll }}
            </a>
          </div>
        </li>-->
      </ul>

      <div class="social-icons">
        <div class="social-fb"></div>
        <div class="social-in"></div>
        <div class="social-tw"></div>
        <div class="social-ln"></div>
      </div>

    </div>
    <div class="navbar-toggler">
      <input type="button" :class="{check: true, active: menuOpen}"  @click="toggleMenu"/>
    </div>
</div>
  </nav>
</header>
</template>

<script>
import { getTranslation } from '../libs/drupalClient'
export default {
  name: 'the-header',
  data() {
    return {
      windowScrolled:false,
      menuOpen: false,
      submenuOpen: null,
      submenuVisible:true,

      //static json for initial menu, structure to retrieve from drupal jsonapi
      menuItems: []
    };
  },
  computed: {
    languages() {
      return this.$store.state.languages
    },
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    }
  },
  methods: {
    //navigation menu structure (routing) based on drupal jsonapi
    async fetchMenuItems() {
      var menu = []
      var items = []
      var ch = []

      await fetch(`${process.env.VUE_APP_ENDPOINT}/jsonapi/menu_items/main`)
        .then(res => res.json())
        .then(json => items = json.data)

      //for each fetched route
      items.forEach(item => {
        ch = []

        //for each menu item with no parent
        if(item.attributes.parent === "" || item.attributes.parent === undefined) {
          //if the number of its children is 0
          if(items.filter(it => it.attributes.parent === item.id).length==0) {
            //push it into the menu as a top-level route
            menu.push({
              label: item.attributes.title,
              url: item.attributes.url
            })

          } else {
            //else search for its children
            items.filter(it => it.attributes.parent === item.id)
              //and create an array of routes
              .forEach(child => ch.push({
                label: child.attributes.title,
                url: child.attributes.url
              }))
            //then push them into the menu as a sub-level route (for that corresponding top-level menu)
            menu.push({
              label: item.attributes.title,
              children: ch
            })
          }
        }
      })

      //create the menu
      this.menuItems = menu

    },
    toggleMenu() {
      this.menuOpen ? this.closeMenu() : this.openMenu();
      this.openSubmenu(null);
    },
    openMenu() {
      document.querySelector("html").classList.add("menu-open");
      document.body.classList.add("menu-open");
      this.menuOpen = true;
    },
    hideSubmenu(){
        // hide temporary sub menu after click
        this.submenuVisible=false
        setTimeout(()=>{
          this.submenuVisible=true
        },100)
      },
    closeMenu() {
      document.querySelector("html").classList.remove("menu-open");
      document.body.classList.remove("menu-open");
      this.openSubmenu(null);
      this.menuOpen = false;

      this.hideSubmenu()
    },
    //just one submenu can stay open at a time
    openSubmenu(index) {
      if (this.submenuOpen === index) {
        this.submenuOpen = null;
      } else {
        this.submenuOpen = index;
      }
    },
    changelang(lang) {
      localStorage.setItem('LS_LANG_KEY', lang)
      this.$store.commit('SET_CURRENT_LANG', lang)
    },
    handleScroll() {

      if(window.pageYOffset){
        this.windowScrolled = true
      } else {
        this.windowScrolled = false
      }
      
    }
  },
  //as page is created, fetch for backend routing
  created() {
    this.fetchMenuItems()
    window.addEventListener('scroll', this.handleScroll)
  },
  watch: {
    lang() {
      this.fetchMenuItems()

      getTranslation(this.$route.name, this.lang)
      .then(res => {
        localStorage.setItem('LS_ROUTE_KEY', res)  
      })

    //console.log(this.$store.state.currentLang)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>