<template>
  <div id="app">
    <the-header></the-header>
    <cookie-banner></cookie-banner>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from './components/TheHeader.vue'
import TheFooter from './components/TheFooter.vue'
import CookieBanner from './components/CookieBanner.vue'

export default {
  components: { 
    TheHeader,
    TheFooter,
    CookieBanner
  },
  data: () => {
    return {
      metas: {}
    }
  },
  metaInfo() {
    return {
      title: this.metas.metadata ? (this.metas.metadata.title + ' | Experotary') : 'Experotary',
      meta: [
        {
          name: 'description',
          content: this.metas.metadata ? this.metas.metadata.description : ''
        },
        {
          property: 'og:title',
          content: this.metas.metadata ? this.metas.metadata.title : ''
        },
        {
          property: 'og:site_name',
          content: 'Experotary'
        },
        {
          property: 'og:type',
          content: this.metas.messages ? this.metas.messages.bundle : ''
        },
        {
          property: 'og:image',
          content: this.metas.metadata ? this.metas.metadata.image : (process.env.VUE_APP_ENDPOINT + '/social-image.jpg')
        },
        {
          name: 'robots',
          content: 'index,follow'
        }
      ]
    }
  },
  methods: {
    refreshPage () {
      localStorage.setItem('LS_LANG_KEY', this.$store.state.currentLang)
      this.$store.commit('SET_CURRENT_LANG', localStorage.getItem('LS_LANG_KEY'))
		},
    fetchMetas() {
      if(this.$route.fullPath=='/') {
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=/home')
        .then(res => res.json())
        .then(json => {
          //console.log('FETCH META', json)
          json.metadata.title = "Homepage"
          this.metas = json
        })
      } else if(this.$route.fullPath=='/gli-esperti') {
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=/gli-esperti')
        .then(res => res.json())
        .then(json => {
          //console.log('FETCH META', json)
          json.metadata.title = "Gli Esperti"
          this.metas = json
        })
      } else if(this.$route.fullPath=='/chi-supportiamo') {
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=/chi-supportiamo')
        .then(res => res.json())
        .then(json => {
          //console.log('FETCH META', json)
          json.metadata.title = "Chi Supportiamo"
          this.metas = json
        })
      } else {
        fetch(process.env.VUE_APP_ENDPOINT + '/meta?url=' + this.$route.fullPath)
        .then(res => res.json())
        .then(json => {
          //console.log('FETCH META', json)
          this.metas = json
        })
      }
    }
  },
  computed: {
    routeFullPath() {
      return this.$route.fullPath
    },
    lang() {
      return this.$store.state.currentLang
    }
  },
  mounted(){
    this.refreshPage()
    this.fetchMetas()
  },
  watch: {
    routeFullPath() {
      this.fetchMetas() 
    }
  }
}
</script>

<style lang="scss">
@import "common.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}
</style>
