import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    data: {},
    lastPage: '',
    currentLang: 'it',
    defaultLang: '',
    languages: [],
    richiestaStep: 1,
    currentExpertId: '',
    sentForm: {
      type: "",
      name: "",
      surname: "",
      email: "",
      donation_amount: 0,
      donation_project: "",
      expert_contact: ""
    }
  },
  mutations: {
    SET_DATA: function(state, payload) {
      state.data = payload;
    },
    SET_SINGLE_DATA: function(state, payload) {
      state[payload.field] = payload.data;
    },
    SET_CURRENT_LANG: function(state, payload) {
      state.currentLang = payload;
    },
    SET_LAST_PAGE: function(state, payload) {
      state.lastPage = payload;
    },
    SET_DEFAULT_LANG: function(state, payload) {
      state.defaultLang = payload;
    },
    SET_LANGUAGES: function(state, payload) {
      state.languages = payload;
    },
    SET_EXPERT: function(state, payload) {
      state.currentExpertId = payload;
    },
    SET_FORM_DATA: function(state, payload) {
      state.sentForm = payload
    },
    SET_ACTIVE_STEP: function(state, payload) {
      state.richiestaStep = payload
    }
  },
  modules: {
  }
})
