<template>
    <footer class="footer">
        <div class="sitemap container-espe">
            
            <button class="gototop" @click="gototop"></button>
            <div class="footer-column logo-column">
                <router-link class="logo-link" :to="'/' + (lang==defaultLang ? '' : lang)">
                    <img class="logo-img" src="../assets/logo.svg" alt="">
                </router-link>
                <!-- <p>Contatti</p> -->
                <p class="contacts">Tel. 040 362801</p>
                <a class="contacts" href="mailto:rotarytrieste@rotarytrieste.com">rotarytrieste@rotarytrieste.com</a>
            </div>

            <div class="footer-column main-menu" v-for="(column, index) in this.footerItems" :key="'footer'+index">
                <keep-alive v-for="(page, i) in column" :key="'page'+i">
                    <router-link v-if="!page.children" 
                        :to="page.url">{{ page.label }}
                    </router-link>
                    <div v-else>
                        <h3>{{ page.label }}</h3>
                        <router-link v-for="(child, ii) in page.children" :key="'child'+ii" :to="child.url">{{ child.label }}</router-link>
                    </div>
                </keep-alive>
            </div>

            <div class="footer-column contacts">
                <p class="social-column">Seguici anche su:</p>
                <div class="social">
                    <a href="https://www.instagram.com/rotaryinternational/?hl=it" target="_blank"><img src="../assets/icon-footer-social-in.svg" alt=""></a>
                    <a href="https://www.facebook.com/rotarytrieste" target="_blank"><img src="../assets/icon-footer-social-fb.svg" alt=""></a>
                </div>
            </div>

        </div>
        <div class="container-espe">
            <div class="last-column">
                <p>&copy; 2021 Copyright Experotary</p>
                <div class="privacy main-menu">
                    <!--<router-link to="/termini-e-condizioni">Termini e Condizioni</router-link>-->
                    <router-link to="/cookie-policy">Cookie Policy</router-link>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'the-footer',
        data: () => {
            return {
                footerItems: [],
                columnLength: 2
            }
        },
        computed: {
            lang() {
                return this.$store.state.currentLang
            },
            defaultLang() {
                return this.$store.state.defaultLang
            }
        },
        methods: {
            gototop() {
                window.scrollTo({
                    top: document.getElementById('totop').getBoundingClientRect().top,
                    behavior: 'smooth'
                })
            },
            async fetchfooterItems() {
                var menu = []
                var items = []
                var ch = []

                await fetch(`${process.env.VUE_APP_ENDPOINT}${this.lang!=this.defaultLang ? ('/'+this.lang) : ''}/jsonapi/menu_items/main`)
                .then(res => res.json())
                .then(json => {
                    items = json.data
                })

                //for each fetched route
                items.forEach((item, index) => {
                    ch = []

                    //for each menu item with no parent
                    if (item.attributes.parent === "" || item.attributes.parent === undefined) {
                        //if the number of its children is 0
                        if (items.filter(it => it.attributes.parent === item.id).length == 0) {
                            //push it into the menu as a top-level route
                            if(menu[Math.floor(index/this.columnLength)]==undefined) 
                                menu[Math.floor(index/this.columnLength)] = []

                            menu[Math.floor(index/this.columnLength)].push({
                                label: item.attributes.title,
                                url: item.attributes.url
                            })

                        } else {
                            //else search for its children
                            items.filter(it => it.attributes.parent === item.id)
                                //and create an array of routes
                                .forEach(child => ch.push({
                                    label: child.attributes.title,
                                    url: child.attributes.url
                                }))
                            //then push them into the menu as a sub-level route (for that corresponding top-level menu)
                            if(menu[Math.floor(index/this.columnLength)]==undefined) 
                                menu[Math.floor(index/this.columnLength)] = []

                            menu[Math.floor(index/this.columnLength)].push({
                                label: item.attributes.title,
                                children: ch
                            })
                        }
                    }
                })
                this.footerItems = menu
                //console.log(menu)
            }
        },
        mounted() {
            this.fetchfooterItems()
        },
        watch: {}
    }
</script>

<style lang="scss">
</style>