import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueGtag from "vue-gtag"
import VueMeta from 'vue-meta'

import { router } from './router/index'

router().then(router => {
  Vue.use(VueGtag, {
    config: { id: "G-X70XRSK6ZS" }
  }, router);

  Vue.use(VueMeta)

  //MANAGINg TASTO BACK DEL BROWSER
  //IN SEZIONE DI RICHIESTA CONFRONTO
  window.popStateDetected = false
  window.addEventListener('popstate', () => {
    window.popStateDetected = true
  })

  router.beforeEach((to, from, next) => {
    const IsItABackButton = window.popStateDetected
    window.popStateDetected = false

    if(to.name=="RichiediConfronto")
      store.commit('SET_ACTIVE_STEP', 1)

    if (IsItABackButton && from.name=="RichiediConfronto") {
      //SE SONO NEL MEZZO DEL FORM, SEMPLICEMENTE TORNA AGLI STEP PRECEDENTI
      if(store.state.richiestaStep>1) {
        store.commit('SET_ACTIVE_STEP', store.state.richiestaStep-1)
        next(false)

      } else {
        //CHIEDE CONFERMA PRIMA DI USCIRE DALLA PAGINA
        var r = confirm('Tornando indietro perderai i dati inseriti fino ad ora. Continuare?')
        if(r) 
          next()
        else
          next(false)
      }
    } else 
      next()
  })

  router.afterEach((to) => {
    //language come impostato nello STORE
    var lfs = store.state.currentLang

    //language come selezionato dall'URL PATH
    var lfp = to.fullPath.substring(1, 4)

    //controlla i primi due caratteri dell'url
    //se sono contenuti nell'array delle lingue disponibili + sono seguiti da un '/' o da nessun carattere
    if(store.state.languages.includes(lfp.substring(0, 2)) && (lfp[2]=='/' || lfp[2]==undefined)) {

      //allora è stata selezionata una nuova lingua direttamente dall'url
      //impostazione diretta della lingua (es: quando si arriva da un link condiviso)
      //console.log('SET CURRENT LANGUAGE', lfp.substring(0, 2))
      store.commit('SET_CURRENT_LANG', lfp.substring(0, 2))

    } else {
      //altrimenti significa che i caratteri seguenti il dominio definiscono direttamente una route
      //console.log('SET DEFAULT LANGUAGE', lfs)
      store.commit('SET_CURRENT_LANG', lfs)
    }
  })

  Vue.config.productionTip = false

  new Vue({
    router,
    store,
    render: (h) => h(App)
  }).$mount("#app")

})