<template>
  <div id="totop" class="template-page">

    <header-intro v-if="pagedata && pagedata.attributes" 
      :addedclass="addedclass"
      :title="pagedata.attributes.title"
      :subtitle="pagedata.attributes.field_home_intro ? pagedata.attributes.field_home_intro.processed : ''"
      :backimg="bannerimg" 
      :fieldname="fieldname">
    </header-intro>

    <div :class="['backstep', addedclass]">
      <slot>
        <!-- here VUE inserts content of wrapped page called in other templates -->
      </slot>
    </div>
    
  </div>
</template>

<script>
import HeaderIntro from '../components/HeaderIntro.vue';
export default {
  name: 'basic-page',
  components: { HeaderIntro },
  data: () => {
    return {
    }
  },
  props: {
    pagedata: Object,
    bannerimg: Object,
    addedclass: String,
    fieldname: String
  },
  mounted(){
    localStorage.setItem('LS_ROUTE_KEY', this.$route.path)

    if(this.$route.fullPath.substring(1, 4)!=this.$store.state.currentLang+'/') {

      if(this.$route.fullPath[3]=='/')
        this.$store.commit('SET_CURRENT_LANG', this.$route.fullPath.substring(1, 3))
      else this.$store.commit('SET_CURRENT_LANG', this.$store.state.defaultLang)
    }
  }
}
</script>

<style lang="scss">
</style>
